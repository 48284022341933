import {
  GET_PAGES,
  GET_PAGES_SUCCESS,
  GET_PAGES_FAIL,
  GET_PAGE_DETAILS,
  GET_PAGE_DETAILS_SUCCESS,
  GET_PAGE_DETAILS_FAIL,
  CREATE_PAGES_SUCCESS,
  UPDATE_PAGES,
  UPDATE_PAGES_SUCCESS,
  UPDATE_PAGES_FAIL,
  DELETE_PAGES,
  DELETE_PAGES_SUCCESS,
  DELETE_PAGES_FAIL,
  CREATE_PAGES,
  CREATE_PAGES_FAIL,
  CREATE_SECTION,
  CREATE_SECTION_SUCCESS,
  CREATE_SECTION_FAIL,
  UPDATE_SECTION,
  UPDATE_SECTION_SUCCESS,
  UPDATE_SECTION_FAIL,
  DELETE_SECTION,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_FAIL,
  GET_ALL_HOME_PAGES,
  GET_ALL_HOME_PAGES_SUCCESS,
  GET_ALL_HOME_PAGES_FAIL,
  UPDATE_HOME_PAGE_STATUS,
  UPDATE_HOME_PAGE_STATUS_SUCCESS,
  UPDATE_HOME_PAGE_STATUS_FAIL,
  GET_ALL_DELETED_PAGES,
  GET_ALL_DELETED_PAGES_SUCCESS,
  GET_ALL_DELETED_PAGES_FAIL,
  HARD_DELETE_PAGE,
  HARD_DELETE_PAGE_SUCCESS,
  HARD_DELETE_PAGE_FAIL,
  RESTORE_PAGE,
  RESTORE_PAGE_SUCCESS,
  RESTORE_PAGE_FAIL,
  GET_RESTORE_PAGE_FAIL,
  GET_RESTORE_PAGE_SUCCESS,
  GET_RESTORE_PAGE,
  CHECK_PAGE_TITLE,
  CHECK_PAGE_TITLE_SUCCESS,
  CHECK_PAGE_TITLE_FAIL,
  GET_DUPLICATE_PAGE,
  GET_DUPLICATE_PAGE_SUCCESS,
  GET_DUPLICATE_PAGE_FAIL,
  GET_DUPLICATE_SECTION,
  GET_DUPLICATE_SECTION_SUCCESS,
  GET_DUPLICATE_SECTION_FAIL,
  GET_DEFAULT_SECTION,
  GET_DEFAULT_SECTION_SUCCESS,
  GET_DEFAULT_SECTION_FAIL,
} from "./actionTypes"

//all pages
export const getPages = (page, limit, searchText) => ({
  type: GET_PAGES,
  payload: { page, limit, searchText },
})

//all pages
export const getPagesSuccess = pages => ({
  type: GET_PAGES_SUCCESS,
  payload: pages,
})

export const getPagesFail = error => ({
  type: GET_PAGES_FAIL,
  payload: error,
})

export const getPageDetails = pageId => ({
  type: GET_PAGE_DETAILS,
  payload: pageId,
})

export const getPageDetailsSuccess = pageDetails => ({
  type: GET_PAGE_DETAILS_SUCCESS,
  payload: pageDetails,
})

export const getPageDetailsFail = error => ({
  type: GET_PAGE_DETAILS_FAIL,
  payload: error,
})

export const createPages = (pages, history) => ({
  type: CREATE_PAGES,
  payload: { pages, history },
})

export const createPagesSuccess = pages => ({
  type: CREATE_PAGES_SUCCESS,
  payload: pages,
})

export const createPagesFail = error => ({
  type: CREATE_PAGES_FAIL,
  payload: error,
})

export const updatePages = (page, pageId, history, setCloseModal) => ({
  type: UPDATE_PAGES,
  payload: { page, pageId, history, setCloseModal },
})

export const updatePagesSuccess = page => ({
  type: UPDATE_PAGES_SUCCESS,
  payload: page,
})

export const updatePagesFail = error => ({
  type: UPDATE_PAGES_FAIL,
  payload: error,
})

export const deletePages = (pageId, history) => ({
  type: DELETE_PAGES,
  payload: { pageId, history },
})

export const deletePagesSuccess = page => ({
  type: DELETE_PAGES_SUCCESS,
  payload: page,
})

export const deletePagesFail = error => ({
  type: DELETE_PAGES_FAIL,
  payload: error,
})
//section
export const getDefaultSection = () => ({
  type: GET_DEFAULT_SECTION,
})

export const getDefaultSectionSuccess = section => ({
  type: GET_DEFAULT_SECTION_SUCCESS,
  payload: section,
})

export const getDefaultSectionFail = error => ({
  type: GET_DEFAULT_SECTION_FAIL,
  payload: error,
})

export const createSection = (section, onCloseClick, pageId) => ({
  type: CREATE_SECTION,
  payload: { section, onCloseClick, pageId },
})

export const createSectionSuccess = section => ({
  type: CREATE_SECTION_SUCCESS,
  payload: section,
})

export const createSectionFail = error => ({
  type: CREATE_SECTION_FAIL,
  payload: error,
})

export const updateSection = (section, sectionId, onCloseClick) => ({
  type: UPDATE_SECTION,
  payload: { section, sectionId, onCloseClick },
})

export const updateSectionSuccess = section => ({
  type: UPDATE_SECTION_SUCCESS,
  payload: section,
})

export const updateSectionFail = error => ({
  type: UPDATE_SECTION_FAIL,
  payload: error,
})

export const deleteSection = (sectionId, history) => ({
  type: DELETE_SECTION,
  payload: { sectionId, history },
})

export const deleteSectionSuccess = section => ({
  type: DELETE_SECTION_SUCCESS,
  payload: section,
})

export const deleteSectionFail = error => ({
  type: DELETE_SECTION_FAIL,
  payload: error,
})

// HOME PAGE

export const getAllHomePages = (page, limit, searchText) => ({
  type: GET_ALL_HOME_PAGES,
  payload: { page, limit, searchText },
})

export const getAllHomePageSuccess = homePages => ({
  type: GET_ALL_HOME_PAGES_SUCCESS,
  payload: homePages,
})

export const getAllHomePagesFail = error => ({
  type: GET_ALL_HOME_PAGES_FAIL,
  payload: error,
})

export const updateHomePageStatus = pageId => ({
  type: UPDATE_HOME_PAGE_STATUS,
  payload: pageId,
})

export const updateHomePageStatusSuccess = homePageDetails => ({
  type: UPDATE_HOME_PAGE_STATUS_SUCCESS,
  payload: homePageDetails,
})

export const updateHomePageStatusFail = error => ({
  type: UPDATE_HOME_PAGE_STATUS_FAIL,
  payload: error,
})

export const getAllDeletedPages = (page, limit, searchText) => ({
  type: GET_ALL_DELETED_PAGES,
  payload: { page, limit, searchText },
})

export const getAllDeletedPagesSuccess = deleted => ({
  type: GET_ALL_DELETED_PAGES_SUCCESS,
  payload: deleted,
})

export const getAllDeletedPagesFail = error => ({
  type: GET_ALL_DELETED_PAGES_FAIL,
  payload: error,
})

export const hardDeletePage = pageId => ({
  type: HARD_DELETE_PAGE,
  payload: pageId,
})

export const hardDeletePageSuccess = pages => ({
  type: HARD_DELETE_PAGE_SUCCESS,
  payload: pages,
})

export const hardDeletePageFail = error => ({
  type: HARD_DELETE_PAGE_FAIL,
  payload: error,
})

export const getRestorePage = pageId => ({
  type: GET_RESTORE_PAGE,
  payload: pageId,
})

export const getRestorePageSuccess = pages => ({
  type: GET_RESTORE_PAGE_SUCCESS,
  payload: pages,
})

export const getRestorePageFail = error => ({
  type: GET_RESTORE_PAGE_FAIL,
  payload: error,
})

export const checkPageTitle = (title, pageId) => ({
  type: CHECK_PAGE_TITLE,
  payload: { title, pageId },
})

export const checkPageTitleSuccess = response => ({
  type: CHECK_PAGE_TITLE_SUCCESS,
  payload: { response },
})

export const checkPageTitleFail = error => ({
  type: CHECK_PAGE_TITLE_FAIL,
  payload: error,
})

export const getDuplicatePage = pageId => ({
  type: GET_DUPLICATE_PAGE,
  payload: { pageId },
})

export const getDuplicatePageSuccess = pageDuplicate => ({
  type: GET_DUPLICATE_PAGE_SUCCESS,
  payload: pageDuplicate,
})

export const getDuplicatePageFail = error => ({
  type: GET_DUPLICATE_PAGE_FAIL,
  payload: error,
})

export const getDuplicateSection = (sectionId, handleUpdatePage) => ({
  type: GET_DUPLICATE_SECTION,
  payload: { sectionId, handleUpdatePage },
})

export const getDuplicateSectionSuccess = section => ({
  type: GET_DUPLICATE_SECTION_SUCCESS,
  payload: section,
})
export const getDuplicateSectionFail = error => ({
  type: GET_DUPLICATE_SECTION_FAIL,
  payload: error,
})
