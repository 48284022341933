/* PROJECTS */
export const GET_PAGES = "GET_PAGES"
export const GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS"
export const GET_PAGES_FAIL = "GET_PAGES_FAIL"

export const GET_PAGE_DETAILS = "GET_PAGE_DETAILS"
export const GET_PAGE_DETAILS_SUCCESS = "GET_PAGE_DETAILS_SUCCESS"
export const GET_PAGE_DETAILS_FAIL = "GET_PAGE_DETAILS_FAIL"

export const CREATE_PAGES = "CREATE_PAGES"
export const CREATE_PAGES_SUCCESS = "CREATE_PAGES_SUCCESS"
export const CREATE_PAGES_FAIL = "CREATE_PAGES_FAIL"

export const UPDATE_PAGES = "UPDATE_PAGES"
export const UPDATE_PAGES_SUCCESS = "UPDATE_PAGES_SUCCESS"
export const UPDATE_PAGES_FAIL = "UPDATE_PAGES_FAIL"

export const DELETE_PAGES = "DELETE_PAGES"
export const DELETE_PAGES_SUCCESS = "DELETE_PAGES_SUCCESS"
export const DELETE_PAGES_FAIL = "DELETE_PAGES_FAIL"

export const GET_DEFAULT_SECTION = "GET_DEFAULT_SECTION"
export const GET_DEFAULT_SECTION_SUCCESS = "GET_DEFAULT_SECTION_SUCCESS"
export const GET_DEFAULT_SECTION_FAIL = "GET_DEFAULT_SECTION_FAIL"

export const CREATE_SECTION = "CREATE_SECTION"
export const CREATE_SECTION_SUCCESS = "CREATE_SECTION_SUCCESS"
export const CREATE_SECTION_FAIL = "CREATE_SECTION_FAIL"

export const UPDATE_SECTION = "UPDATE_SECTION"
export const UPDATE_SECTION_SUCCESS = "UPDATE_SECTION_SUCCESS"
export const UPDATE_SECTION_FAIL = "UPDATE_SECTION_FAIL"

export const DELETE_SECTION = "DELETE_SECTION"
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS"
export const DELETE_SECTION_FAIL = "DELETE_SECTION_FAIL"

export const GET_ALL_HOME_PAGES = "GET_ALL_HOME_PAGES"
export const GET_ALL_HOME_PAGES_SUCCESS = "GET_ALL_HOME_PAGES_SUCCESS"
export const GET_ALL_HOME_PAGES_FAIL = "GET_ALL_HOME_PAGES_FAIL"

export const UPDATE_HOME_PAGE_STATUS = "UPDATE_HOME_PAGE_STATUS"
export const UPDATE_HOME_PAGE_STATUS_SUCCESS = "UPDATE_HOME_PAGE_STATUS_SUCCESS"
export const UPDATE_HOME_PAGE_STATUS_FAIL = "UPDATE_HOME_PAGE_STATUS_FAIL"

export const GET_ALL_DELETED_PAGES = "GET_ALL_DELETED_PAGES"
export const GET_ALL_DELETED_PAGES_SUCCESS = "GET_ALL_DELETED_PAGES_SUCCESS"
export const GET_ALL_DELETED_PAGES_FAIL = "GET_ALL_DELETED_PAGES_FAIL"

export const HARD_DELETE_PAGE = "HARD_DELETE_PAGE"
export const HARD_DELETE_PAGE_SUCCESS = "HARD_DELETE_PAGE_SUCCESS"
export const HARD_DELETE_PAGE_FAIL = "HARD_DELETE_PAGE_FAIL"

export const GET_RESTORE_PAGE = "GET_RESTORE_PAGE"
export const GET_RESTORE_PAGE_SUCCESS = "GET_RESTORE_PAGE_SUCCESS"
export const GET_RESTORE_PAGE_FAIL = "GET_RESTORE_PAGE_FAIL"

export const CHECK_PAGE_TITLE = "CHECK_PAGE_TITLE"
export const CHECK_PAGE_TITLE_SUCCESS = "CHECK_PAGE_TITLE_SUCCESS"
export const CHECK_PAGE_TITLE_FAIL = "CHECK_PAGE_TITLE_FAIL"

export const GET_DUPLICATE_PAGE = "GET_DUPLICATE_PAGE"
export const GET_DUPLICATE_PAGE_SUCCESS = "GET_DUPLICATE_PAGE_SUCCESS"
export const GET_DUPLICATE_PAGE_FAIL = "GET_DUPLICATE_PAGE_FAIL"

export const GET_DUPLICATE_SECTION = "GET_DUPLICATE_SECTION"
export const GET_DUPLICATE_SECTION_SUCCESS = "GET_DUPLICATE_SECTION_SUCCESS"
export const GET_DUPLICATE_SECTION_FAIL = "GET_DUPLICATE_SECTION_FAIL"
