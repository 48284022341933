import {
  GET_BANNERS,
  GET_BANNERS_FAIL,
  GET_BANNERS_SUCCESS,
  GET_BANNER_DETAILS,
  GET_BANNER_DETAILS_SUCCESS,
  GET_BANNER_DETAILS_FAIL,
  CREATE_BANNER,
  CREATE_BANNER_SUCCESS,
  CREATE_BANNER_FAIL,
  UPDATE_BANNER,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAIL,
  DELETE_BANNER,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_FAIL,
} from "./actionTypes"

//all categories
export const getBanners = () => ({
  type: GET_BANNERS,
})

//all categories
export const getBannersSuccess = categories => ({
  type: GET_BANNERS_SUCCESS,
  payload: categories,
})

export const getBannersFail = error => ({
  type: GET_BANNERS_FAIL,
  payload: error,
})

export const getBannerDetails = bannerId => ({
  type: GET_BANNER_DETAILS,
  payload: bannerId,
})

export const getBannerDetailsSuccess = bannerDetails => ({
  type: GET_BANNER_DETAILS_SUCCESS,
  payload: bannerDetails,
})

export const getBannerDetailsFail = error => ({
  type: GET_BANNER_DETAILS_FAIL,
  payload: error,
})

export const createBanner = (banner, history) => ({
  type: CREATE_BANNER,
  payload: {banner, history},
})

export const createBannerSuccess = banner => ({
  type: CREATE_BANNER_SUCCESS,
  payload: banner
})

export const createBannerFail = error => ({
  type: CREATE_BANNER_FAIL,
  payload: error,
})

export const updateBanner = (banner, bannerId, history) => ({
  type: UPDATE_BANNER,
  payload: {banner, bannerId, history},
})

export const updateBannerSuccess = banner => ({
  type: UPDATE_BANNER_SUCCESS,
  payload: banner,
})

export const updateBannerFail = error => ({
  type: UPDATE_BANNER_FAIL,
  payload: error,
})

export const deleteBanner = (bannerId, history) => ({
  type: DELETE_BANNER, 
  bannerId, history
})

export const deleteBannerSuccess = banner => ({
  type: DELETE_BANNER_SUCCESS,
  payload: banner,
})

export const deleteBannerFail = error => ({
  type: DELETE_BANNER_FAIL,
  payload: error,
})