import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody } from "reactstrap"
import label from "assets/images/other/label.png"
import Spinner from "components/Common/Spinner"
import { useSelector } from "react-redux"
import { FaTimes } from "react-icons/fa"

const ShiprocketLabelPdfPopup = ({
  onCloseClick,
  show,
  loading,
  shipmentIds,
}) => {
  const { shiprocketLabel } = useSelector(state => ({
    shiprocketLabel: state.Orders.shiprocketLabel,
  }))

  const shiprocketNotCreated =
    shiprocketLabel?.not_created &&
    Object?.entries(shiprocketLabel?.not_created)?.map(([title, message]) => ({
      title,
      message,
    }))

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
      className="delete-modal"
    >
      <ModalBody style={deleteModalBodyStyle} className="p-0 position-relative">
        <FaTimes
          onClick={onCloseClick}
          className="text-muted cursor-pointer position-absolute"
          size={"16px"}
          style={{ top: "10px", right: "10px" }}
        />
        <div className="text-center pt-4 d-flex align-items-center flex-column justify-content-center pt-0">
          <div>
            <img src={label} alt="" height={150} />
          </div>
          <h6>Label Generated </h6>
          <p className="pb-0 mb-1">
            {" "}
            Shipment Id :{" "}
            {shipmentIds?.map((i, index) => (
              <span className="text-success" key={index}>
                {i} {index + 1 === shipmentIds?.length ? "" : ","}&nbsp;
              </span>
            ))}
          </p>
          {shiprocketNotCreated?.length >= 1 && (
            <p className="font-size-12 text-muted pb-0 mb-0">
              Label Generated Failed : <br />
              {shiprocketNotCreated?.map((i, key) => (
                <p key={key} className="m-0 p-0">
                  <span>{i?.title} : </span>
                  <span>{i?.message}</span>
                </p>
              ))}
            </p>
          )}
        </div>
        <div className="hstack h-100 gap-2 mt-4 mb-3 px-4">
          <a href={shiprocketLabel?.label_url} target="_self" className="w-100">
            <button
              style={{ borderRadius: 10 }}
              className="btn btn-success w-100"
              onClick={onCloseClick}
            >
              Download Label
              {loading && <Spinner />}
            </button>
          </a>
        </div>
      </ModalBody>
    </Modal>
  )
}

ShiprocketLabelPdfPopup.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  loading: PropTypes.bool,
  shipmentIds: PropTypes.array,
}

export default ShiprocketLabelPdfPopup

const deleteModalStyle = {
  width: 350,
}
const deleteModalBodyStyle = {
  minHeight: 300,
}
