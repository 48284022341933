import {
  GET_REVENUES,
  GET_REVENUES_FAIL,
  GET_REVENUES_SUCCESS,
  GET_REVENUE_DETAILS,
  GET_REVENUE_DETAILS_SUCCESS,
  GET_REVENUE_DETAILS_FAIL,
} from "./actionTypes"

//all settings
export const getRevenues = (page, store, sortByDate) => ({
  type: GET_REVENUES,
  payload: { page, store, sortByDate },
})

//all settings
export const getRevenuesSuccess = revenues => ({
  type: GET_REVENUES_SUCCESS,
  payload: revenues,
})

export const getRevenuesFail = error => ({
  type: GET_REVENUES_FAIL,
  payload: error,
})

export const getRevenueDetails = revenueId => ({
  type: GET_REVENUE_DETAILS,
  payload: { revenueId },
})

export const getRevenueDetailsSuccess = revenueDetails => ({
  type: GET_REVENUE_DETAILS_SUCCESS,
  payload: revenueDetails,
})

export const getRevenueDetailsFail = error => ({
  type: GET_REVENUE_DETAILS_FAIL,
  payload: error,
})
