import React from 'react'
import PropTypes from "prop-types"
import Rating from 'react-rating'
import { FaRegStar, FaStar } from 'react-icons/fa'

function MyRating(props) {
    return (
        <Rating
            emptySymbol={<FaRegStar size={props?.size ? props?.size : '1.5rem'} />}
            fullSymbol={<FaStar size={props?.size ? props?.size : '1.5rem'} />}
            className={props?.className}
            {...props}
        />
    )
}

export default MyRating


MyRating.propTypes = {
    props: PropTypes.any,
    className: PropTypes.string,
    size: PropTypes.string
}