import {
  GET_SHOPS,
  GET_SHOPS_SUCCESS,
  GET_SHOPS_FAIL,
  GET_UNAPPROVED_SHOPS,
  GET_UNAPPROVED_SHOPS_SUCCESS,
  GET_UNAPPROVED_SHOPS_FAIL,
  GET_BANNED_SHOPS,
  GET_BANNED_SHOPS_SUCCESS,
  GET_BANNED_SHOPS_FAIL,
  CHANGE_SHOP_STATUS,
  CHANGE_SHOP_STATUS_SUCCESS,
  CHANGE_SHOP_STATUS_FAIL,
  GET_SHOP_DETAILS,
  GET_SHOP_DETAILS_SUCCESS,
  GET_SHOP_DETAILS_FAIL,
  CREATE_SHOP,
  CREATE_SHOP_SUCCESS,
  CREATE_SHOP_FAIL,
  UPDATE_SHOP,
  UPDATE_SHOP_SUCCESS,
  UPDATE_SHOP_FAIL,
  DELETE_SHOP,
  DELETE_SHOP_SUCCESS,
  DELETE_SHOP_FAIL
} from "./actionTypes"

export const getShops = (page) => ({
  type: GET_SHOPS,
  payload: {page}
})

export const getShopsSuccess = shops => ({
  type: GET_SHOPS_SUCCESS,
  payload: shops,
})

export const getShopsFail = error => ({
  type: GET_SHOPS_FAIL,
  payload: error,
})

export const getUnapprovedShops = (page) => ({
  type: GET_UNAPPROVED_SHOPS,
  payload: {page}
})

export const getUnapprovedShopsSuccess = shops => ({
  type: GET_UNAPPROVED_SHOPS_SUCCESS,
  payload: shops,
})

export const getUnapprovedShopsFail = error => ({
  type: GET_UNAPPROVED_SHOPS_FAIL,
  payload: error,
})

export const getBannedShops = (page) => ({
  type: GET_BANNED_SHOPS,
  payload: {page}
})

export const getBannedShopsSuccess = shops => ({
  type: GET_BANNED_SHOPS_SUCCESS,
  payload: shops,
})

export const getBannedShopsFail = error => ({
  type: GET_BANNED_SHOPS_FAIL,
  payload: error,
})

export const changeShopStatus = (history) => ({
  type: CHANGE_SHOP_STATUS,
  payload: {history}
})

export const changeShopStatusSuccess = shop => ({
  type: CHANGE_SHOP_STATUS_SUCCESS,
  payload: shop,
})

export const changeShopStatusFail = error => ({
  type: CHANGE_SHOP_STATUS_FAIL,
  payload: error,
})

export const getShopDetails = shopId => ({
  type: GET_SHOP_DETAILS,
  payload: shopId,
})

export const getShopDetailsSuccess = shopDetails => ({
  type: GET_SHOP_DETAILS_SUCCESS,
  payload: shopDetails,
})

export const getShopDetailsFail = error => ({
  type: GET_SHOP_DETAILS_FAIL,
  payload: error,
})

export const createShop = (shop, history) => ({
  type: CREATE_SHOP,
  payload: {shop, history}
})

export const createShopSuccess = shop => ({
  type: CREATE_SHOP_SUCCESS,
  payload: shop,
})

export const createShopFail = error => ({
  type: CREATE_SHOP_FAIL,
  payload: error,
})

// export const getShopProductDetail = productId => ({
//   type: GET_SHOP_PRODUCT_DETAILS,
//   productId,
// })

export const updateShop = (shop, shopId, history) => ({
  type: UPDATE_SHOP,
  payload: { shop, shopId, history }
})

export const updateShopSuccess = shop => ({
  type: UPDATE_SHOP_SUCCESS,
  payload: shop,
})

export const updateShopFail = error => ({
  type: UPDATE_SHOP_FAIL,
  payload: error,
})

export const deleteShop = (shopId, history) => ({
  type: DELETE_SHOP,
  shopId,
  history
})

export const deleteShopSuccess = shop => ({
  type: DELETE_SHOP_SUCCESS,
  payload: shop,
})

export const deleteShopFail = error => ({
  type: DELETE_SHOP_FAIL,
  payload: error,
})



