import React from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Badge,
  CardSubtitle,
  CardTitle,
} from "reactstrap"
import { useSelector } from "react-redux"

import BootstrapTable from "react-bootstrap-table-next"

import { map } from "lodash"
import moment from "moment"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import "assets/scss/datatables.scss"
import { defaultImg } from "assets/images"

const Users = () => {
  const { loading, couponDetails } = useSelector(state => ({
    loading: state.Coupon.loading,
    couponDetails: state.Coupon.couponDetails,
  }))

  const users = couponDetails?.deductedUsersList

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "count",
      text: "Count",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Joined On",
      sort: true,
    },
    {
      dataField: "lastLoginData",
      text: "Last Login",
      sort: true,
    },
  ]

  const userData = map(users, (item, index) => ({
    ...item,
    imageData: (
      <img
        className="rounded-circle header-profile-user"
        style={{ width: "70px", height: "70px" }}
        src={
          item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
        }
      />
    ),
    roleData: (
      <Badge
        className={
          item.isGuest
            ? "font-size-12 badge-soft-primary"
            : item.role == "admin"
            ? "font-size-12 badge-soft-secondary"
            : "font-size-12 badge-soft-success"
        }
        color=""
        pill
      >
        {item.isGuest ? "guest" : item.role}
      </Badge>
    ),
    createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm A"),

    lastLoginData:
      item.lastLogin && moment(item.lastLogin).format("DD/MM/YYYY - h:mm A"),
  }))

  const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList()
  }

  const handleTableColumn = () => {
    return columns
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={handleTableColumn()}
                  data={userData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="d-flex align-items-center justify-content-between">
                        <CardTitle className="mb-3">
                          Deducted Users List
                        </CardTitle>

                        <div className="search-box mb-2 d-inline-block">
                          <div className="position-relative ">
                            <SearchBar
                              {...toolkitProps.searchProps}
                              style={{ width: 300 }}
                            />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </div>

                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {users?.length ? (
                            <Row>
                              <p>Total Users : {users?.length}</p>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p
                              className="my-4 mx-3 "
                              style={{ textTransform: "capitalize" }}
                            >
                              No users deducted yet!
                            </p>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Users
