import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, FormGroup, Badge } from "reactstrap"
import { Button, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"

//actions
import { LanguageSwitch } from "hooks/LanguageSwitch"
import { deleteGroup, getGroups, getStores } from "store/actions"

import "../../assets/scss/datatables.scss"

import DeleteModal from "components/Common/DeleteModal"
import { defaultImg } from "assets/images"

const Groups = () => {
  const dispatch = useDispatch()

  const { groups, loading } = useSelector(state => ({
    groups: state.Group.groups,
    loading: state.Group.loading,
    // stores: state.Stores.stores?.stores,
    // StoreLoading: state.Stores.loading,
  }))

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [limit, setLimit] = useState("10")

  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })

  // const [storeSearchText, setStoreSearchText] = useState("")
  // const [selectedStore, setSelectedStore] = useState("All Store")
  // const [finalStoreId, setFinalStoreId] = useState()

  const handleSwitchChange = id => {
    // dispatch(changeProductStatus(id, history))
  }

  useEffect(() => {
    dispatch(getGroups(page, limit, searchText))
  }, [dispatch, page, searchText])

  // useEffect(() => {
  //   dispatch(getStores(1, storeSearchText))
  // }, [dispatch, storeSearchText])

  const total = groups?.length

  //pagination
  const totalPages = Math.ceil(total / limit)
  const pages = range(1, totalPages + 1)

  const columns = [
    {
      dataField: "primaryLang.title",
      text: "Primary Name",
      sort: true,
    },
    // {
    //   dataField: "secondaryLang.title",
    //   text: "Secondary Name",
    //   sort: true,
    // },
    // {
    //   dataField: "store.storeName",
    //   text: "Store Name",
    //   sort: true,
    // },
    {
      dataField: "platform",
      text: "Platform",
      sort: true,
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
    },
    {
      dataField: "layout",
      text: "Layout",
      sort: true,
    },
    {
      dataField: "imageData",
      text: "Banner",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
    },
  ]

  const groupsData = map(groups, (item, index) => ({
    ...item,
    imageData: (
      <>
        <img
          src={
            item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
          }
          width="50px"
        />
      </>
    ),
    platform: (
      <Badge className="px-2 py-1 badge-soft-info ">{item.platform}</Badge>
    ),
    activeData: (
      <>
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleSwitchChange(item?._id)
            }}
            checked={item?.isActive}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action: (
      <div>
        <Link to={`/groups/update/${item?._id}`}>
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
          >
            <i
              className="bx bx-show-alt me-2"
              style={{ color: "green", fontSize: "22px" }}
            />
          </Button>
        </Link>
        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", fontSize: "20px" }}
          />
        </Button>
      </div>
    ),
  }))

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteGroup(isOpen?.id, history))
  }

  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }

  const isMult = LanguageSwitch()?.isMult

  columns[0].text = isMult ? "Primary Name" : "Name"

  //store&nbsp;
  // const debounceStoreSearch = debounce(value => setStoreSearchText(value), 600)
  // const handleEnters = textEntered => {
  //   debounceStoreSearch(textEntered)
  // }

  function handlerProductFinalValue(event) {
    setSelectedStore(event.label)
    setFinalStoreId(event.id)
  }

  // const storeOptions = [
  //   {
  //     label: "All Store",
  //     id: "",
  //   },
  //   {
  //     options: stores?.map((result, index) => ({
  //       key: index,
  //       label: result?.storeName,
  //       id: result?._id,
  //     })),
  //   },
  // ]

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={
                    isMult
                      ? columns
                      : columns?.filter(
                          i => i.dataField !== "secondaryLang.title"
                        )
                  }
                  data={groupsData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col xl={6} lg={6} md={6} sm={12}>
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <AvForm>
                                <AvField
                                  name="searchText"
                                  placeholder="Search"
                                  type="text"
                                  onChange={e => setSearchText(e.target.value)}
                                />
                              </AvForm>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        {/* <Col xl={4} lg={4} md={4} sm={6} className="">
                          <FormGroup className="w-100 custom_store_select">
                            <div className="mb-3 ajax-select mt-lg-0 select2-container">
                              <Select
                                onInputChange={handleEnters}
                                value={selectedStore}
                                placeholder={selectedStore}
                                onChange={handlerProductFinalValue}
                                options={storeOptions}
                                classNamePrefix="select-selection"
                                isLoading={StoreLoading}
                              />
                            </div>
                          </FormGroup>
                        </Col> */}
                        <Col
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          className="d-flex align-items-center justify-content-end"
                        >
                          <div className="text-sm-end">
                            <Link to="/groups/create/new">
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                              >
                                <i className="mdi mdi-plus me-1" />
                                Create Group
                              </Button>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {groups?.length ? (
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p className="my-4 mx-3 ">No Groups!</p>
                          )}
                          <MyPagination
                            totalPages={pages}
                            page={page}
                            setPage={setPage}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Groups
