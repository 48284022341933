import { AvField, AvForm } from "availity-reactstrap-validation"
import { map } from "lodash"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import {
  Badge,
  Button,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import { createArea_list } from "store/actions"

const AreaModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()

  const [EnteredPincodes, setEnteredPinCodes] = useState("")

  const [pinArray, setPinArray] = useState([])

  const handlePincodeLabel = () => {
    if (EnteredPincodes?.length >= 1) {
      setPinArray([...pinArray, EnteredPincodes])
      setEnteredPinCodes("")
    }
  }

  const [areaName, setareaName] = useState("")

  const handleDeletePincode = item => {
    const updatePin = pinArray?.filter(i => i !== item)

    setPinArray(updatePin)
  }

  const handleValidSubmit = () => {
    const createArea = {
      name: areaName,
      pinCodes: pinArray,
    }
    dispatch(createArea_list(createArea))
    onCloseClick()
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-4 px-5 ">
        <CardTitle>Add New Area</CardTitle>
        <AvForm
        // onValidSubmit={(e, v) => {
        //   handleValidSubmit(e, v)
        // }}
        >
          <Row>
            <Col lg={12}>
              <AvField
                name="name"
                type="text"
                placeholder="Enter Area Name"
                className="my-3"
                onChange={e => setareaName(e.target.value)}
              />
            </Col>
            <Col lg={6}>
              <AvField
                name="pinCode"
                type="text"
                placeholder="Enter Pincode"
                onChange={v => {
                  setEnteredPinCodes(v.target.value)
                }}
                value={EnteredPincodes}
              />
            </Col>
            <Col lg={6}>
              <Button
                className="mx-2 px-2 btn-info"
                onClick={handlePincodeLabel}
              >
                Add
              </Button>
            </Col>
          </Row>
        </AvForm>
        <div className="d-flex my-3 flex-wrap">
          {map(pinArray, (item, key) => (
            <Badge
              className={
                "font-size-12 me-2 p-2 badge-soft-info mt-2 d-flex align-items-center justify-content-center "
              }
              style={{
                width: "fit-content",
              }}
              pill
              key={key}
            >
              {item}
              <i
                className="bx bx-x mx-2 bx-tada-hover"
                style={{
                  fontSize: "1.2rem",
                  cursor: "pointer",
                  color: "#ff0000ab",
                }}
                onClick={() => handleDeletePincode(item)}
              ></i>
            </Badge>
          ))}
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <div className="text-center mt-3">
            <button
              type="button"
              className="btn btn-danger btn-sm btn-lg ms-2"
              onClick={onCloseClick}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-success btn-lg ms-2"
              onClick={handleValidSubmit}
            >
              Confirm!
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

AreaModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default AreaModal
