import {
  GET_STORES,
  GET_STORES_SUCCESS,
  GET_STORES_FAIL,
  GET_STORE_LIST,
  GET_STORE_LIST_SUCCESS,
  GET_STORE_LIST_FAIL,
  GET_UNAPPROVED_STORES,
  GET_UNAPPROVED_STORES_SUCCESS,
  GET_UNAPPROVED_STORES_FAIL,
  GET_BANNED_STORES,
  GET_BANNED_STORES_SUCCESS,
  GET_BANNED_STORES_FAIL,
  CHANGE_STORE_STATUS,
  CHANGE_STORE_STATUS_SUCCESS,
  CHANGE_STORE_STATUS_FAIL,
  GET_STORE_DETAILS,
  GET_STORE_DETAILS_SUCCESS,
  GET_STORE_DETAILS_FAIL,
  CREATE_STORE,
  CREATE_STORE_SUCCESS,
  CREATE_STORE_FAIL,
  UPDATE_STORE,
  UPDATE_STORE_SUCCESS,
  UPDATE_STORE_FAIL,
  DELETE_STORE,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  stores: [],
  storeList: [],
  unapprovedStores: [],
  bannedStores: [],
  storeDetails: {},
  storeVarients: {},
  error: {},
  loading: false,
  createStoreError: null,
}

const Stores = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORES:
    case GET_STORE_LIST:
    case GET_UNAPPROVED_STORES:
    case GET_BANNED_STORES:
    case CHANGE_STORE_STATUS:
    case GET_STORE_DETAILS:
    case CREATE_STORE:
    case UPDATE_STORE:
    case DELETE_STORE:
      return {
        ...state,
        loading: true,
      }

    case CREATE_STORE_FAIL:
      return {
        ...state,
        createStoreError: action.payload,
        loading: false,
      }
    case GET_STORES_FAIL:
    case GET_STORE_LIST_FAIL:
    case GET_UNAPPROVED_STORES_FAIL:
    case GET_BANNED_STORES_FAIL:
    case CHANGE_STORE_STATUS_FAIL:
    case GET_STORE_DETAILS_FAIL:
    case DELETE_STORE_FAIL:
    case UPDATE_STORE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_STORES_SUCCESS:
      return {
        ...state,
        error: {},
        stores: action.payload,
        loading: false,
      }

    case GET_STORE_LIST_SUCCESS:
      return {
        ...state,
        storeList: action.payload,
        error: {},
        loading: false,
      }

    case GET_UNAPPROVED_STORES_SUCCESS:
      return {
        ...state,
        unapprovedStores: action.payload,
        error: {},
        loading: false,
      }

    case GET_BANNED_STORES_SUCCESS:
      return {
        ...state,
        bannedStores: action.payload,
        error: {},
        loading: false,
      }

    case CHANGE_STORE_STATUS_SUCCESS:
      return {
        ...state,
        error: {},
        payload: action.payload,
        // shops: [...state.shops].map(shop =>
        //   shop._id.toString() === action.payload.shops._id.toString()
        //     ? { shop, ...action.payload.shops }
        //     : shop
        // ),
        // loading: false,
      }

    case GET_STORE_DETAILS_SUCCESS:
      return {
        ...state,
        storeDetails: action.payload.data.findStore,
        //TODO: change findShopProduct to shopProduct in backend
        storeVarients: action.payload.data.storeVarients,
        loading: false,
        error: {},
      }

    case CREATE_STORE_SUCCESS:
      return {
        ...state,
        stores: [...state.stores, action.payload],
        createStoreError: null,
        error: {},
        loading: false,
      }

    case UPDATE_STORE_SUCCESS:
      return {
        ...state,
        stores: [...state.stores].map(store =>
          store._id.toString() === action.payload.data._id.toString()
            ? { store, ...action.payload.data }
            : store
        ),
        error: {},
        loading: false,
      }

    case DELETE_STORE_SUCCESS:
      return {
        ...state,
        stores: state.stores.filter(
          store => store._id.toString() !== action.payload.data._id.toString()
        ),
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default Stores
