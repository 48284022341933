import { call, put, takeEvery } from "redux-saga/effects"

// Settings Redux States
import { GET_REVENUES, GET_REVENUE_DETAILS } from "./actionTypes"
import {
  getRevenuesSuccess,
  getRevenuesFail,
  getRevenueDetailsSuccess,
  getRevenueDetailsFail,
} from "./actions"

//Include Both Helper File with needed methods
import { post, get, ApiPut, del } from "../../helpers/api_helper"

function revenuesApi({ page, store, sortByDate }) {
  if (store) {
    return get(
      `/static/revenue/all?store=${store}&page=${page ? page : 1}&from=${
        sortByDate.from
      }&to=${sortByDate.to}`
    )
  } else {
    return get(
      `/static/revenue/all?page=${page ? page : 1}&from=${sortByDate.from}&to=${
        sortByDate.to
      }`
    )
  }
}

const getRevenueDetailsAPi = ({ revenueId }) => {
  return get(`/static/revenue/shop/${revenueId}`)
}

function* fetchRevenues({ payload }) {
  try {
    const response = yield call(revenuesApi, payload)
    yield put(getRevenuesSuccess(response))
  } catch (error) {
    yield put(getRevenuesFail(error))
  }
}

function* fetchRevenueDetail({ payload: revenueId }) {
  try {
    const response = yield call(getRevenueDetailsAPi, revenueId)
    yield put(getRevenueDetailsSuccess(response))
  } catch (error) {
    yield put(getRevenueDetailsFail(error))
  }
}

function* revenuesSaga() {
  yield takeEvery(GET_REVENUES, fetchRevenues)
  yield takeEvery(GET_REVENUE_DETAILS, fetchRevenueDetail)
}

export default revenuesSaga
