import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_RAW_USERS,
  GET_RAW_USERS_SUCCESS,
  GET_RAW_USERS_FAIL,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_CARTS,
  GET_USER_CARTS_SUCCESS,
  GET_USER_CARTS_FAIL,
  GET_USER_CART_DETAILS,
  GET_USER_CART_DETAILS_SUCCESS,
  GET_USER_CART_DETAILS_FAIL,
  DELETE_USER_CART,
  DELETE_USER_CART_SUCCESS,
  DELETE_USER_CART_FAIL,
  GET_USER_FAVOURITE_DETAILS,
  GET_USER_FAVOURITE_DETAILS_SUCCESS,
  GET_USER_FAVOURITE_DETAILS_FAIL,
  GET_USER_RECENT_VIEWS,
  GET_USER_RECENT_VIEWS_SUCCESS,
  GET_USER_RECENT_VIEWS_FAIL,
  DELETE_USER_FAVOURITE,
  DELETE_USER_FAVOURITE_SUCCESS,
  DELETE_USER_FAVOURITE_FAIL,
  GET_USER_ADDRESS,
  GET_USER_ADDRESS_SUCCESS,
  GET_USER_ADDRESS_FAIL,
  ADD_USER_ADDRESS,
  ADD_USER_ADDRESS_SUCCESS,
  ADD_USER_ADDRESS_FAIL,
  DELETE_USER_ADDRESS,
  DELETE_USER_ADDRESS_SUCCESS,
  DELETE_USER_ADDRESS_FAIL,
  ADD_ITEM_TO_USER_CART,
  ADD_ITEM_TO_USER_CART_SUCCESS,
  ADD_ITEM_TO_USER_CART_FAIL,
  ADD_COUPON_TO_USER_CART,
  ADD_COUPON_TO_USER_CART_SUCCESS,
  ADD_COUPON_TO_USER_CART_FAIL,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  CLEAR_USER_DATA,
} from "./actionTypes"

//all users
export const getUsers = (page, sort, searchText) => ({
  type: GET_USERS,
  payload: { page, sort, searchText },
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

//all raw users
export const getRawUsers = searchText => ({
  type: GET_RAW_USERS,
  payload: { searchText },
})

export const getRawUsersSuccess = rawUsers => ({
  type: GET_RAW_USERS_SUCCESS,
  payload: rawUsers,
})

export const getRawUsersFail = error => ({
  type: GET_RAW_USERS_FAIL,
  payload: error,
})

//user details
export const getUserDetails = userId => ({
  type: GET_USER_DETAILS,
  payload: userId,
})

export const getUserDetailsSuccess = userDetails => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: userDetails,
})

export const getUserDetailsFail = error => ({
  type: GET_USER_DETAILS_FAIL,
  payload: error,
})

//CREATE user
export const createUser = (user, closeModal) => ({
  type: CREATE_USER,
  payload: { user, closeModal },
})

export const createUserSuccess = user => ({
  type: CREATE_USER_SUCCESS,
  payload: user,
})
export const clearUserData = () => ({
  type: CLEAR_USER_DATA,
})

export const createUserFail = error => ({
  type: CREATE_USER_FAIL,
  payload: error,
})

//update user
export const updateUser = (user, userId, history) => ({
  type: UPDATE_USER,
  payload: { user, userId, history },
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

//delete user
export const deleteUser = (userId, history) => ({
  type: DELETE_USER,
  payload: { userId, history },
})

export const deleteUserSuccess = (user, id) => ({
  type: DELETE_USER_SUCCESS,
  payload: { user, id },
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})

//all carts
export const getUserCarts = () => ({
  type: GET_USER_CARTS,
})

export const getUserCartsSuccess = userCarts => ({
  type: GET_USER_CARTS_SUCCESS,
  payload: userCarts,
})

export const getUserCartsFail = error => ({
  type: GET_USER_CARTS_FAIL,
  payload: error,
})

//user details
export const getUserCartDetails = userId => ({
  type: GET_USER_CART_DETAILS,
  payload: userId,
})

export const getUserCartDetailsSuccess = userCartDetails => ({
  type: GET_USER_CART_DETAILS_SUCCESS,
  payload: userCartDetails,
})

export const getUserCartDetailsFail = error => ({
  type: GET_USER_CART_DETAILS_FAIL,
  payload: error,
})

//delete cart
export const deleteUserCart = (userCartId, history) => ({
  type: DELETE_USER_CART,
  userCartId,
  history,
})

export const deleteUserCartSuccess = userCart => ({
  type: DELETE_USER_CART_SUCCESS,
  payload: userCart,
})

export const deleteUserCartFail = error => ({
  type: DELETE_USER_CART_FAIL,
  payload: error,
})

//user favourite details
export const getUserFavouriteDetails = userId => ({
  type: GET_USER_FAVOURITE_DETAILS,
  payload: userId,
})

export const getUserFavouriteDetailsSuccess = userFavouriteDetails => ({
  type: GET_USER_FAVOURITE_DETAILS_SUCCESS,
  payload: userFavouriteDetails,
})

export const getUserFavouriteDetailsFail = error => ({
  type: GET_USER_FAVOURITE_DETAILS_FAIL,
  payload: error,
})

//user recent Views
export const getUserRecentViews = userId => ({
  type: GET_USER_RECENT_VIEWS,
  payload: userId,
})

export const getUserRecentViewsSuccess = userRecentViews => ({
  type: GET_USER_RECENT_VIEWS_SUCCESS,
  payload: userRecentViews,
})

export const getUserRecentViewsFail = error => ({
  type: GET_USER_RECENT_VIEWS_FAIL,
  payload: error,
})

//delete user favourite
export const deleteUserFavourite = (userFavouriteId, history) => ({
  type: DELETE_USER_FAVOURITE,
  userFavouriteId,
  history,
})

export const deleteUserFavouriteSuccess = userFavourite => ({
  type: DELETE_USER_FAVOURITE_SUCCESS,
  payload: userFavourite,
})

export const deleteUserFavouriteFail = error => ({
  type: DELETE_USER_FAVOURITE_FAIL,
  payload: error,
})
//address
export const getUserAddress = userId => ({
  type: GET_USER_ADDRESS,
  payload: userId,
})
export const getUserAddressSuccess = userAddress => ({
  type: GET_USER_ADDRESS_SUCCESS,
  payload: userAddress,
})
export const getUserAddressFail = error => ({
  type: GET_USER_ADDRESS_FAIL,
  payload: error,
})

export const addUserAddress = (address, userId, onCloseClick) => ({
  type: ADD_USER_ADDRESS,
  payload: { address, userId, onCloseClick },
})
export const addUserAddressSuccess = userAddress => ({
  type: ADD_USER_ADDRESS_SUCCESS,
  payload: userAddress,
})
export const addUserAddressFail = error => ({
  type: ADD_USER_ADDRESS_FAIL,
  payload: error,
})

export const deleteUserAddress = (addressId, userId) => ({
  type: DELETE_USER_ADDRESS,
  payload: { addressId, userId },
})
export const deleteUserAddressSuccess = addressId => ({
  type: DELETE_USER_ADDRESS_SUCCESS,
  payload: addressId,
})
export const deleteUserAddressFail = error => ({
  type: DELETE_USER_ADDRESS_FAIL,
  payload: error,
})

//Add Item to User Cart
export const addItemToUserCart = (userId, item) => ({
  type: ADD_ITEM_TO_USER_CART,
  payload: { userId, item },
})

export const addItemToUserCartSuccess = userCart => ({
  type: ADD_ITEM_TO_USER_CART_SUCCESS,
  payload: userCart,
})

export const addItemToUserCartFail = error => ({
  type: ADD_ITEM_TO_USER_CART_FAIL,
  payload: error,
})
//Add COUPON to User Cart
export const addCouponToUserCart = (userId, coupon) => ({
  type: ADD_COUPON_TO_USER_CART,
  payload: { userId, coupon },
})

export const addCouponToUserCartSuccess = userCart => ({
  type: ADD_COUPON_TO_USER_CART_SUCCESS,
  payload: userCart,
})

export const addCouponToUserCartFail = error => ({
  type: ADD_COUPON_TO_USER_CART_FAIL,
  payload: error,
})
