import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORY_DETAILS,
  GET_CATEGORY_DETAILS_SUCCESS,
  GET_CATEGORY_DETAILS_FAIL,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  CREATE_CATEGORY,
} from "./actionTypes"

const INIT_STATE = {
  categories: [],
  categoryDetails: {},
  error: {},
  loading: false,
}

const Category = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
    case GET_CATEGORY_DETAILS:
    case UPDATE_CATEGORY:
    case CREATE_CATEGORY:
      return {
        ...state,
        loading: true,
      }
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        error: {},
        loading: false,
      }
    case GET_CATEGORY_DETAILS_FAIL:
    case CREATE_CATEGORY_FAIL:
    case DELETE_CATEGORY_FAIL:
    case UPDATE_CATEGORY_FAIL:
    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_CATEGORY_DETAILS_SUCCESS:
      return {
        ...state,
        categoryDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, action.payload],
        error: {},
        loading: false,
      }

    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories].map(category =>
          category._id.toString() === action.payload._id.toString()
            ? { category, ...action.payload }
            : category
        ),
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default Category
