import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_RAW_USERS,
  GET_RAW_USERS_SUCCESS,
  GET_RAW_USERS_FAIL,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_CARTS,
  GET_USER_CARTS_SUCCESS,
  GET_USER_CARTS_FAIL,
  GET_USER_CART_DETAILS,
  GET_USER_CART_DETAILS_SUCCESS,
  GET_USER_CART_DETAILS_FAIL,
  DELETE_USER_CART,
  DELETE_USER_CART_SUCCESS,
  DELETE_USER_CART_FAIL,
  GET_USER_FAVOURITE_DETAILS,
  GET_USER_FAVOURITE_DETAILS_SUCCESS,
  GET_USER_FAVOURITE_DETAILS_FAIL,
  GET_USER_RECENT_VIEWS,
  GET_USER_RECENT_VIEWS_SUCCESS,
  GET_USER_RECENT_VIEWS_FAIL,
  DELETE_USER_FAVOURITE,
  DELETE_USER_FAVOURITE_SUCCESS,
  DELETE_USER_FAVOURITE_FAIL,
  GET_USER_ADDRESS_SUCCESS,
  GET_USER_ADDRESS,
  GET_USER_ADDRESS_FAIL,
  ADD_USER_ADDRESS,
  ADD_USER_ADDRESS_FAIL,
  ADD_USER_ADDRESS_SUCCESS,
  DELETE_USER_ADDRESS,
  DELETE_USER_ADDRESS_FAIL,
  DELETE_USER_ADDRESS_SUCCESS,
  ADD_ITEM_TO_USER_CART_SUCCESS,
  ADD_ITEM_TO_USER_CART_FAIL,
  ADD_ITEM_TO_USER_CART,
  ADD_COUPON_TO_USER_CART,
  ADD_COUPON_TO_USER_CART_FAIL,
  ADD_COUPON_TO_USER_CART_SUCCESS,
  CREATE_USER_FAIL,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CLEAR_USER_DATA,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  rawUsers: [],
  userCarts: [],
  userCartDetails: {},
  userFavouriteDetails: {},
  userRecentViews: {},
  userAddress: [],
  addressLoad: false,
  userDetails: {},
  userVarients: {},
  error: {},
  loading: false,
  cartAddLoading: false,
  couponLoad: false,
  couponRes: {},
  createShopProductError: null,
  userCreateLoad: false,
  recentlyCreatedUser: {},
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
    case GET_RAW_USERS:
    case GET_USER_CARTS:
    case GET_USER_DETAILS:
    case GET_USER_CART_DETAILS:
    case GET_USER_FAVOURITE_DETAILS:
    case GET_USER_RECENT_VIEWS:
    case GET_RAW_USERS:
    case DELETE_USER:
    case DELETE_USER_CART:
    case DELETE_USER_FAVOURITE:
      return {
        ...state,
        loading: true,
      }

    case CREATE_USER:
      return {
        ...state,
        userCreateLoad: true,
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        userCreateLoad: false,
        rawUsers: {
          users: [...state.rawUsers?.users, action.payload],
          total: state.rawUsers?.total + 1,
        },
        recentlyCreatedUser: action.payload,
      }
    case CREATE_USER_FAIL:
      return {
        ...state,
        userCreateLoad: false,
        error: action.payload,
      }
    case CLEAR_USER_DATA:
      return {
        ...state,
        recentlyCreatedUser: {},
      }
    case ADD_COUPON_TO_USER_CART:
      return {
        ...state,
        couponLoad: true,
      }
    case ADD_ITEM_TO_USER_CART:
      return {
        ...state,
        cartAddLoading: true,
      }

    case GET_USER_ADDRESS:
    case ADD_USER_ADDRESS:
    case DELETE_USER_ADDRESS:
      return {
        ...state,
        addressLoad: true,
      }

    //FAIL
    case ADD_USER_ADDRESS_FAIL:
    case GET_USER_ADDRESS_FAIL:
    case GET_USERS_FAIL:
    case GET_RAW_USERS_FAIL:
    case GET_USER_DETAILS_FAIL:
    case UPDATE_USER_FAIL:
    case GET_RAW_USERS_FAIL:
    case DELETE_USER_FAIL:
    case GET_USER_CART_DETAILS_FAIL:
    case GET_USER_CARTS_FAIL:
    case DELETE_USER_CART_FAIL:
    case DELETE_USER_CART_FAIL:
    case GET_USER_RECENT_VIEWS_FAIL:
    case GET_USER_FAVOURITE_DETAILS_FAIL:
    case DELETE_USER_FAVOURITE_FAIL:
    case DELETE_USER_ADDRESS_FAIL:
    case ADD_ITEM_TO_USER_CART_FAIL:
      return {
        ...state,
        error: action.payload,
        addressLoad: false,
        loading: false,
        cartAddLoading: false,
        couponLoad: false,
      }
    case ADD_COUPON_TO_USER_CART_FAIL:
      return {
        ...state,
        couponRes: action.payload,
        couponLoad: false,
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        error: {},
        loading: false,
      }

    case GET_RAW_USERS_SUCCESS:
      return {
        ...state,
        rawUsers: action.payload,
        error: {},
        loading: false,
      }

    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        userVarients: action.payload.userVarients,
        error: {},
        loading: false,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          users: state.users.users.map(user =>
            user._id.toString() === action.payload._id.toString()
              ? { ...user, isPrime: action.payload.isPrime }
              : user
          ),
        },
        error: {},
        loading: false,
      }

    case GET_RAW_USERS_SUCCESS:
      return {
        ...state,
        rawUsers: action.payload,
        error: {},
        loading: false,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: {
          users: state?.users?.users.filter(
            user => user._id !== action.payload.id
          ),
        },
        error: {},
        loading: false,
      }

    case GET_USER_CARTS_SUCCESS:
      return {
        ...state,
        userCarts: action.payload,
        error: {},
        loading: false,
      }

    case GET_USER_CART_DETAILS_SUCCESS:
      return {
        ...state,
        userCartDetails: action.payload,
        error: {},
        loading: false,
      }

    case DELETE_USER_CART_SUCCESS:
      return {
        ...state,
        userCarts: state.userCarts.filter(
          userCart => userCart._id.toString() !== action.payload._id.toString()
        ),
        error: {},
        loading: false,
      }

    case DELETE_USER_CART_SUCCESS:
      return {
        ...state,
        userCarts: state.userCarts.filter(
          userCart => userCart._id.toString() !== action.payload._id.toString()
        ),
        loading: false,
        error: {},
      }

    case GET_USER_FAVOURITE_DETAILS_SUCCESS:
      return {
        ...state,
        error: {},
        userFavouriteDetails: action.payload,
      }

    case DELETE_USER_FAVOURITE_SUCCESS:
      return {
        ...state,
        userFavourites: state.userFavourites.filter(
          userFavourite =>
            userFavourite._id.toString() !== action.payload._id.toString()
        ),
        error: {},
        loading: false,
      }

    case GET_USER_RECENT_VIEWS_SUCCESS:
      return {
        ...state,
        error: {},
        userRecentViews: action.payload,
      }

    case GET_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        userAddress: action.payload,
        error: {},
        addressLoad: false,
      }
    case ADD_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        userAddress: action.payload,
        error: {},
        addressLoad: false,
      }
    case DELETE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        userAddress: {
          ...state.userAddress,
          shippingAddresses: state.userAddress?.shippingAddresses?.filter(
            i => i._id !== action.payload
          ),
        },
        addressLoad: false,
        error: {},
      }

    case ADD_ITEM_TO_USER_CART_SUCCESS:
      return {
        ...state,
        userCartDetails: action.payload,
        error: {},
        cartAddLoading: false,
      }

    case ADD_COUPON_TO_USER_CART_SUCCESS:
      return {
        ...state,
        couponRes: action.payload,
        error: {},
        couponLoad: false,
      }

    default:
      return state
  }
}

export default Users
