import React, { Fragment, useRef } from "react"
import PropTypes from "prop-types"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import JoditEditor from "jodit-react"

const FormEditors = ({ content, setContent }) => {
  const editor = useRef(null)

  //     const [editorState, setEditorState] = useState(() =>
  //       EditorState.createWithContent(convertFromHTML(content))
  //     )
  //   // const [editorState, setEditorState] = useState(convertFromHTML(content));
  //   const handleEditorChange = state => {
  //     setEditorState(state)
  //     convertContentToHTML()
  //   }
  //   const convertContentToHTML = () => {
  //     let currentContentAsHTML = convertToHTML(editorState.getCurrentContent())
  //     setContent(currentContentAsHTML)
  //   }
  //   useEffect(() => {
  //     setEditorState(() =>
  //       EditorState.createWithContent(convertFromHTML(content))
  //     )
  //   }, [])

  return (
    <Fragment>
      <div className="db__column">
        {/* <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          value={content ? content : ""}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "colorPicker",
              "embedded",
            ],
            inline: { inDropdown: true },
            blockType: { inDropdown: true },
            fontSize: { inDropdown: true },
            fontFamily: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            colorPicker: { inDropdown: true },
            embedded: { inDropdown: true },
          }}
        /> */}
        <JoditEditor
          ref={editor}
          value={content}
          // tabIndex={1} // tabIndex of textarea
          onChange={text => setContent(text)}
          // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
        />
      </div>
    </Fragment>
  )
}

export default FormEditors

FormEditors.propTypes = {
  content: PropTypes.string,
  setContent: PropTypes.func,
}
