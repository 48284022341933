import {
  GET_PRODUCT_PERFORMANCE,
  GET_PRODUCT_PERFORMANCE_SUCCESS,
  GET_PRODUCT_PERFORMANCE_FAIL,
} from "./actionTypes"


export const getProductPerformance = (page, limit, searchText, sortByDate) => ({
  type: GET_PRODUCT_PERFORMANCE,
  payload: { page, limit, searchText, sortByDate },
})

export const getProductPerformanceSuccess = productPerformanceData => ({
  type: GET_PRODUCT_PERFORMANCE_SUCCESS,
  payload: productPerformanceData,
})

export const getProductPerformanceFail = error => ({
  type: GET_PRODUCT_PERFORMANCE_FAIL,
  payload: error,
})