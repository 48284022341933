import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//actions
import { getSettings, updateSettings } from "store/actions"
import MetaTag from "components/Common/Meta-tag"

const UpdateSettings = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { settings, loading } = useSelector(state => ({
    settings: state.Settings.settings,
    loading: state.Settings.loading,
  }))

  function handleValidSubmit(values) {
    const updateSettingsData = {
      homeShop: settings?.homeShop?._id,
      returnDuration: values.returnDuration,
      shopSearchDistance: parseFloat(values.shopSearchDistance),
      privacyPolicy: values.privacyPolicy,
    }

    dispatch(updateSettings(updateSettingsData, settings?._id, history))
  }

  useEffect(() => {
    dispatch(getSettings())
  }, [dispatch])

  return (
    <>
      <MetaTag title="Update Settings" />

      <div className="page-content">
        <Breadcrumbs title="Banners" breadcrumbItem="Update Settings" />
        <Container fluid>
          <div className="container-fluid">
            <Row className="justify-content-center">
              <Col lg={12} md={12}>
                <Card>
                  <CardBody>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <Row>
                        <Col className="col-12 d-flex flex-wrap">
                          <div className="col-3 mb-3 mx-3">
                            <AvField
                              name="homeShop._id"
                              label="Main Shop"
                              type="text"
                              value={settings?.homeShop?._id || ""}
                            />
                          </div>
                          <div className="col-3 mb-3 mx-3">
                            <AvField
                              name="shopSearchDistance"
                              label="Shop Distance(*/Mtrs)"
                              type="phone"
                              value={settings?.shopSearchDistance || ""}
                            />
                          </div>
                          <div className="col-3 mb-3 mx-3">
                            <AvField
                              name="returnDuration"
                              label="Return Duration"
                              type="number"
                              value={settings?.returnDuration || ""}
                            />
                          </div>

                          <div className="col-12 mb-4 px-3">
                            <AvField
                              name="privacyPolicy"
                              label="Our Privacy Policy"
                              type="textarea"
                              value={settings?.privacyPolicy || ""}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateSettings
