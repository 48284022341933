import React from "react"
import PropTypes from "prop-types"

function ImageInput({ htmlFor }) {
  return (
    <label
      htmlFor={htmlFor}
      style={{
        height: "100px",
        width: "100px",
        background: "#bdc0c257",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        flexDirection: "column",
        margin: "0",
      }}
    >
      <i
        className="bx bxs-image-add bx-burst-hover"
        style={{
          fontSize: "2.4rem",
          color: "white",
        }}
      ></i>
      <p style={{ fontSize: "11px" }}>Choose files</p>
    </label>
  )
}

export default ImageInput

ImageInput.propTypes = {
  htmlFor: PropTypes.any,
}
