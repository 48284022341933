/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/* RAW USERS */
export const GET_RAW_USERS = "GET_RAW_USERS"
export const GET_RAW_USERS_SUCCESS = "GET_RAW_USERS_SUCCESS"
export const GET_RAW_USERS_FAIL = "GET_RAW_USERS_FAIL"

/* USER_DETAILS */
export const GET_USER_DETAILS = "GET_USER_DETAILS"
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS"
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL"

/* Edit USER*/
export const CREATE_USER = "CREATE_USER"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAIL = "CREATE_USER_FAIL"

export const CLEAR_USER_DATA = "CLEAR_USER_DATA"

/* Edit USER*/
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/* Delete USER*/
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

// Cart Items

/* CARTS */
export const GET_USER_CARTS = "GET_USER_CARTS"
export const GET_USER_CARTS_SUCCESS = "GET_USER_CARTS_SUCCESS"
export const GET_USER_CARTS_FAIL = "GET_USER_CARTS_FAIL"

/* CART_DETAILS */
export const GET_USER_CART_DETAILS = "GET_USER_CART_DETAILS"
export const GET_USER_CART_DETAILS_SUCCESS = "GET_USER_CART_DETAILS_SUCCESS"
export const GET_USER_CART_DETAILS_FAIL = "GET_USER_CART_DETAILS_FAIL"

/* Delete CART*/
export const DELETE_USER_CART = "DELETE_USER_CART"
export const DELETE_USER_CART_SUCCESS = "DELETE_USER_CART_SUCCESS"
export const DELETE_USER_CART_FAIL = "DELETE_USER_CART_FAIL"

/* FAVOURITE_DETAILS */
export const GET_USER_FAVOURITE_DETAILS = "GET_USER_FAVOURITE_DETAILS"
export const GET_USER_FAVOURITE_DETAILS_SUCCESS =
  "GET_USER_FAVOURITE_DETAILS_SUCCESS"
export const GET_USER_FAVOURITE_DETAILS_FAIL = "GET_USER_FAVOURITE_DETAILS_FAIL"

/* RECENT_VIEWS */
export const GET_USER_RECENT_VIEWS = "GET_USER_RECENT_VIEWS"
export const GET_USER_RECENT_VIEWS_SUCCESS = "GET_USER_RECENT_VIEWS_SUCCESS"
export const GET_USER_RECENT_VIEWS_FAIL = "GET_USER_RECENT_VIEWS_FAIL"

/* Delete FAVOURITE*/
export const DELETE_USER_FAVOURITE = "DELETE_USER_FAVOURITE"
export const DELETE_USER_FAVOURITE_SUCCESS = "DELETE_USER_FAVOURITE_SUCCESS"
export const DELETE_USER_FAVOURITE_FAIL = "DELETE_USER_FAVOURITE_FAIL"

//user address by admin
export const GET_USER_ADDRESS = "GET_USER_ADDRESS"
export const GET_USER_ADDRESS_SUCCESS = "GET_USER_ADDRESS_SUCCESS"
export const GET_USER_ADDRESS_FAIL = "GET_USER_ADDRESS_FAIL"

export const ADD_USER_ADDRESS = "ADD_USER_ADDRESS"
export const ADD_USER_ADDRESS_SUCCESS = "ADD_USER_ADDRESS_SUCCESS"
export const ADD_USER_ADDRESS_FAIL = "ADD_USER_ADDRESS_FAIL"

export const DELETE_USER_ADDRESS = "DELETE_USER_ADDRESS"
export const DELETE_USER_ADDRESS_SUCCESS = "DELETE_USER_ADDRESS_SUCCESS"
export const DELETE_USER_ADDRESS_FAIL = "DELETE_USER_ADDRESS_FAIL"

// add item to user cart
export const ADD_ITEM_TO_USER_CART = "ADD_ITEM_TO_USER_CART"
export const ADD_ITEM_TO_USER_CART_SUCCESS = "ADD_ITEM_TO_USER_CART_SUCCESS"
export const ADD_ITEM_TO_USER_CART_FAIL = "ADD_ITEM_TO_USER_CART_FAIL"

export const ADD_COUPON_TO_USER_CART = "ADD_COUPON_TO_USER_CART"
export const ADD_COUPON_TO_USER_CART_SUCCESS = "ADD_COUPON_TO_USER_CART_SUCCESS"
export const ADD_COUPON_TO_USER_CART_FAIL = "ADD_COUPON_TO_USER_CART_FAIL"
