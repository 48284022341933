import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Alert, CardTitle } from "reactstrap"
import { Col, Row } from "reactstrap"
import PropTypes from "prop-types"

//actions
import { addCouponToUserCart, getUserCartDetails } from "store/actions"

import Spinner from "components/Common/Spinner"
import { AvField, AvForm } from "availity-reactstrap-validation"

function AddCoupon({ userId }) {
  const dispatch = useDispatch()

  const { couponLoad, couponRes } = useSelector(state => ({
    couponLoad: state.Users.couponLoad,
    couponRes: state.Users.couponRes,
  }))

  const [couponText, setCouponText] = useState("")

  const handleValidateCoupon = () => {
    dispatch(addCouponToUserCart(userId, { couponCode: couponText }))

    dispatch(getUserCartDetails(userId))
  }
  return (
    <Row>
      <Col lg={12}>
        <AvForm className="apply-coupon w-100">
          <AvField
            name="coupon"
            type="text"
            placeholder="Enter Coupon Code..."
            className="w-100"
            onChange={e => setCouponText(e.target.value)}
          />
          <button
            type="button"
            className="btn btn-md text-black white-space-nowrap"
            style={{
              border: "1px solid #ececec",
              backgroundColor: "#ececec",
              flex: 1,
              height: 42,
            }}
            onClick={handleValidateCoupon}
          >
            {couponLoad && <Spinner className="spinner" />}
            Apply Coupon
          </button>
        </AvForm>
      </Col>
      {couponRes?.message && (
        <p className="text-danger py-2 px-3">{couponRes?.message}</p>
      )}
    </Row>
  )
}

export default AddCoupon

AddCoupon.propTypes = {
  userId: PropTypes.string,
  setSelectedUserId: PropTypes.func,
}
