import {
  GET_PRODUCT_PERFORMANCE,
  GET_PRODUCT_PERFORMANCE_SUCCESS,
  GET_PRODUCT_PERFORMANCE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  productPerformanceData: [],
  loading: false,
}

const Performance = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_PERFORMANCE:
      return {
        ...state,
        loading: true,
      }

    case GET_PRODUCT_PERFORMANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PRODUCT_PERFORMANCE_SUCCESS:
      return {
        ...state,
        productPerformanceData: action.payload,
        loading: false,
        error: {},
      }

    default:
      return state
  }
}

export default Performance
