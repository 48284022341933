import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import ImageInput from "./MyInput"
import PropTypes from "prop-types"

function ImageCard({
  onDeleteImg,
  bannerImagesPreview,
  logoImagesPreview,
  onChangeBannerImageHandler,
  onChangeLogoImageHandler,
}) {
  return (
    <>
      <Card>
        <CardBody>
          <div className="form-group px-3">
            <CardTitle className="mb-4">Banner images :</CardTitle>

            <div className="custom-file d-flex justify-content-start align-items-center">
              <input
                type="file"
                name="banner_images"
                className="custom-file-input"
                id="banner_input"
                onChange={onChangeBannerImageHandler}
                multiple
                hidden
              />
              <ImageInput htmlFor="banner_input" />

              <div className="mx-5 d-flex justify-content-start flex-wrap align-items-center">
                {bannerImagesPreview?.map((img, index) => (
                  <div
                    style={{
                      position: "relative",
                    }}
                    className="mb-2 "
                    key={index}
                  >
                    <img
                      src={img?.url}
                      alt="Images Preview"
                      className="mr-2"
                      style={{ marginRight: ".5rem" }}
                      width="75"
                      height="72"
                    />
                    <i
                      className="bx bx-x-circle"
                      style={{
                        color: "red",
                        fontSize: "1.2rem",
                        position: "absolute",
                        top: "2px",
                        right: "12px",
                        background: "#e9e9ea",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={() => onDeleteImg(img?.id)}
                    ></i>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="form-group px-3 mt-5">
            <CardTitle className="mb-4">Logo images :</CardTitle>

            <div className="custom-file d-flex justify-content-start align-items-center">
              <input
                type="file"
                name="product_images"
                className="custom-file-input"
                id="logo_input"
                onChange={onChangeLogoImageHandler}
                multiple
                hidden
              />
              <ImageInput htmlFor="logo_input" />

              <div className="mx-5">
                {logoImagesPreview?.map(img => (
                  <img
                    src={img}
                    key={img}
                    alt="Images Preview"
                    className="mt-3 mr-2"
                    width="65"
                    height="62"
                  />
                ))}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default ImageCard

ImageCard.propTypes = {
  bannerImagesPreview: PropTypes.any,
  logoImagesPreview: PropTypes.any,
  onDeleteImg: PropTypes.func,
  onChangeLogoImageHandler: PropTypes.func,
  onChangeBannerImageHandler: PropTypes.func,
}
