import React from "react"
import { Container } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DatatableTables from "./DatatableTables"
import MetaTag from "components/Common/Meta-tag"

function Order() {
  return (
    <>
      <MetaTag title={"Cancelled Orders"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Cancelled Orders" />
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Order
