import { debounce } from "lodash"
import Select from "react-select"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardTitle } from "reactstrap"
import { Col, FormGroup, Row, Media, Badge } from "reactstrap"
import { isEmpty } from "lodash"
import moment from "moment"
import PropTypes from "prop-types"

//actions
import { clearUserData, getRawUsers, getUserDetails } from "store/actions"

function UserDropDown({ userId, setSelectedUserId, setUserPopup }) {
  const dispatch = useDispatch()

  const { users, userDetails, usersLoading, recentlyCreatedUser } = useSelector(
    state => ({
      users: state.Users.rawUsers?.users,
      userDetails: state.Users.userDetails,
      usersLoading: state.Users.loading,
      recentlyCreatedUser: state.Users.recentlyCreatedUser,
    })
  )

  const [userSearchText, setUserSearchText] = useState("")

  const [selectedUserLabel, setSelectedUserLabel] = useState()

  useEffect(() => {
    dispatch(getRawUsers(userSearchText))
  }, [dispatch, userSearchText])

  useEffect(() => {
    if (recentlyCreatedUser?._id) {
      setSelectedUserId(recentlyCreatedUser?._id)
      setSelectedUserLabel(recentlyCreatedUser?.name)
      dispatch(getUserDetails(recentlyCreatedUser?._id))
    } else if (userId) {
      dispatch(getUserDetails(userId))
    }
  }, [dispatch, userId, recentlyCreatedUser?._id])

  const debounceUserSearch = debounce(value => setUserSearchText(value), 600)
  const handleUserEnters = textEntered => {
    debounceUserSearch(textEntered)
    if (recentlyCreatedUser?._id) {
      dispatch(clearUserData())
    }
  }

  function handlerUserFinalValue(event) {
    setSelectedUserLabel(event.label)
    setSelectedUserId(event.value)
  }

  const userOptions = [
    {
      options:
        users?.length &&
        users?.map((result, index) => ({
          key: index,
          label: `${result?.phone} ${result?.name ? "- " + result?.name : ""}`,
          value: result?._id,
        })),
    },
  ]

  return (
    <div className="select-user">
      <Row>
        <Col lg={!isEmpty(userId) ? 6 : 8}>
          <CardTitle>Search User</CardTitle>
          <FormGroup className="col-12 mb-3">
            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
              <Select
                onInputChange={handleUserEnters}
                value={userId}
                placeholder={selectedUserLabel}
                onChange={handlerUserFinalValue}
                options={userOptions}
                classNamePrefix="select2-selection"
                isLoading={usersLoading}
              />
            </div>
          </FormGroup>
        </Col>
        <Col lg={!isEmpty(userId) ? 6 : 4}>
          <CardTitle>&nbsp;</CardTitle>

          <button
            type="button"
            className="btn btn-success save-customer"
            onClick={() => setUserPopup({ status: true })}
          >
            Create New User
          </button>
        </Col>
        {!isEmpty(userId) && (
          <Col lg="6">
            <Media className="user-card">
              <Media className="align-self-center" body>
                <div className="text-muted">
                  <h5 className="mb-1">
                    {userDetails?.name}{" "}
                    <Badge
                      className={
                        userDetails.isGuest
                          ? "font-size-12 badge-soft-primary"
                          : userDetails.role == "admin"
                          ? "font-size-12 badge-soft-secondary"
                          : "font-size-12 badge-soft-success"
                      }
                      color=""
                      pill
                    >
                      {userDetails?.isGuest ? "guest" : userDetails?.role}
                    </Badge>
                  </h5>
                  {userDetails?.phone && (
                    <p className="mb-0 mt-2 d-flex align-items-center">
                      <i className="bx bx-phone-call text-success font-size-16 mx-1"></i>
                      {userDetails?.phone}
                    </p>
                  )}
                  {userDetails?.email && (
                    <p className="mb-0 mt-2 d-flex align-items-center">
                      <i className="bx bx-mail-send text-success font-size-16 mx-1"></i>
                      {userDetails?.email}
                    </p>
                  )}
                  {userDetails?.createdAt && (
                    <p className="mb-0 mt-2 d-flex align-items-center">
                      <i className="bx bx-group font-size-16 text-success mx-1"></i>
                      Joined On&nbsp;
                      <br />
                      <span className="text">
                        {" "}
                        {moment(userDetails?.createdAt).format(
                          "DD/MM/YYYY - h:mm A"
                        )}
                      </span>
                    </p>
                  )}
                  {userDetails?.lastLogin && (
                    <p className="mb-0 mt-2 d-flex align-items-center">
                      <i className="bx bx-log-in-circle text-success font-size-16 mx-1"></i>
                      Last Login&nbsp; <br />
                      <span className="text-info">
                        {" "}
                        {moment(userDetails?.lastLogin).format(
                          "DD/MM/YYYY - h:mm A"
                        )}
                      </span>
                    </p>
                  )}
                  <Badge className={"font-size-12 badge-soft-success"} pill>
                    {userDetails?.isGuest && "Guest user"}
                  </Badge>{" "}
                  <Badge className={"font-size-12 badge-soft-success"} pill>
                    {userDetails?.isPrime && "Prime user"}
                  </Badge>
                </div>
              </Media>
            </Media>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default UserDropDown

UserDropDown.propTypes = {
  userId: PropTypes.string,
  setSelectedUserId: PropTypes.func,
  setUserPopup: PropTypes.func,
}
