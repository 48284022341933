import { useSelector } from "react-redux"

export const CurrencySwitch = () => {
  const { settings } = useSelector(state => ({
    settings: state.Settings.settings,
  }))

  const currency = settings?.company?.defaultCurrencySymbol

  if (currency) return currency
}
