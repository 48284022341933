import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody } from "reactstrap"
import label from "assets/images/other/label.png"
import { FaTimes } from "react-icons/fa"

const StatusAutoUpdateSuccess = ({ onCloseClick, show, data }) => {
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
      className="delete-modal"
    >
      <ModalBody style={deleteModalBodyStyle} className="p-0 position-relative">
        <FaTimes
          onClick={onCloseClick}
          className="text-muted cursor-pointer position-absolute"
          size={"16px"}
          style={{ top: "10px", right: "10px" }}
        />
        <div className="text-center pt-4 d-flex align-items-center flex-column justify-content-center pt-0">
          <div>
            <img src={label} alt="" height={150} />
          </div>
          <h6>Orders Updated Successfully </h6>
          <p className="pb-0 mb-1">{data}</p>
        </div>
        {/* <div className="hstack h-100 gap-2 mt-4 mb-3 px-4">
          <button
            style={{ borderRadius: 10 }}
            className="btn btn-success w-100"
            onClick={onCloseClick}
          >
            Close
          </button>
        </div> */}
      </ModalBody>
    </Modal>
  )
}

StatusAutoUpdateSuccess.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  data: PropTypes.any,
}

export default StatusAutoUpdateSuccess

const deleteModalStyle = {
  width: 350,
}
const deleteModalBodyStyle = {
  minHeight: 300,
}
