import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,

    GET_DASHBOARD_DATA,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_FAIL
} from "./actionTypes";

const INIT_STATE = {
    chartsData: [],
    dashboardData: {}
};

const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_SUCCESS:
            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state,
                        chartsData: action.payload.data
                    };
                default:
                    return state;
            }
        case API_FAIL:
            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state,
                        chartsDataError: action.payload.error
                    };


                default:
                    return state;
            }
        
        
    //Dashboard Data
        case GET_DASHBOARD_DATA:
            return {
            ...state,
            loading: true,
            }
        
        case GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                dashboardData: action.payload,
                loading: false,
            }
            

        case GET_DASHBOARD_DATA_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        default:
            return state;
    }

}


export default Dashboard;